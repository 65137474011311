import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import "./Folder.css"
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"

const FolderAccess = ({ onLoginSuccess }) => {
  const { connected_id } = useAuth(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('000000000');
  const [waiting, setWaiting] = useState(false);
  const [error_message, setErrorMessage] = useState('');
  useEffect(() => {
    if (connected_id) {
      setWaiting(true);
      if (connected_id !== undefined && connected_id !== null) {
        onLoginSuccess();
      }
    }
  }, [connected_id]);

  const handleChange = (e) => {
    const newPassword = e.target.value;
    const upperPassword = newPassword.toLocaleUpperCase();
    setPassword(upperPassword);
  };

  const handleChangeMail = (e) => {
    setEmail(e.target.value);
  };

  const connect_folder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/connect-folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      onLoginSuccess();
    } catch (err) {
      setErrorMessage('Informations érronnées, connexion impossible')
      console.log(err);
    }
  };

  return (
    <>
      <div className="background-container"></div>
      <div className="container">
        <div className="login-box">
          <div className="login-title">Connexion au dossier</div>
          <div className="login-form">
            <div className="form-group">
              <label className="">Email:</label>
              <input
                type="email"
                name="email"
                className="login-input"
                placeholder="test.exemple@exemple.com"
                onChange={handleChangeMail}
              />
              <label className="">Code de connexion reçu par mail :</label>
              <div className="code-input">
                <input className='formInput' onChange={(e) => handleChange(e)}></input>
              </div>
            </div>
          </div>
          <div className="login-div">
            <button type="button" className="login-button" onClick={connect_folder}>
              Se connecter
            </button>
          </div>
          {error_message !== '' &&
            <p className='error'>{error_message}</p>
          }
          {waiting && <p>En attente</p>}
        </div>
      </div>
    </>
  );
};

export default FolderAccess;

import React from 'react';
import MessageComponent from './Folder_Message';
import "./Folder.css"
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"

const FolderHeader = ({student_name, folder_id}) => {
    return (
        <>
            <div className='folder_header'>
                <h2>{student_name}</h2>
                <MessageComponent student_name={student_name} folder_id={folder_id}></MessageComponent>
            </div>
        </>
    );
};

export default FolderHeader;

import React from 'react';

const Civility = ({ state, dispatch }) => {
  return (

    <div className='classic-container Card'>
      <h3>Candidat</h3>
      <div className="inputs-wrapper">
        <div className="input-container">
          <label htmlFor="nom">Nom :</label>
          <input
            type="text"
            id="nom"
            placeholder="John"
            value={state.nom}
            onChange={(e) => {
              dispatch({
                type: "SET_FIELD",
                field: "nom",
                value: e.target.value,
              });
            }}
          />
        </div>

        <div className="input-container">
          <label htmlFor="prenom">Prénom :</label>
          <input
            type="text"
            id="prenom"
            placeholder="Smith"
            value={state.prenom}
            onChange={(e) =>
              dispatch({
                type: "SET_FIELD",
                field: "prenom",
                value: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="inputs-wrapper">
        <div className="input-container">
          <label htmlFor="civilite">Civilité :</label>
          <select
            className="Selection"
            id="civilite"
            value={state.civility}
            onChange={(e) => {
              dispatch({
                type: "SET_FIELD",
                field: "civility",
                value: e.target.value,
              });
            }}
          >
            <option value="" hidden>
              Choisissez
            </option>
            <option value="madame">Madame</option>
            <option value="monsieur">Monsieur</option>
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="date de naissance">Date de Naissance :</label>
          <input
            type="date"
            id="date de naissance"
            value={state.Date_de_naissance}
            onChange={(e) =>
              dispatch({
                type: "SET_FIELD",
                field: "Date_de_naissance",
                value: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Civility
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";

export function useAuth(_CancelNavigate) {
  const navigate = useNavigate();
  const [connected_first_name, setFirstName] = useState("");
  const [connected_last_name, setLastName] = useState("");
  const [connected_id, setId] = useState("");
  const [connected_email, setEmail] = useState("");
  const [connected_initials, setInitials] = useState("");
  const [connected_permissions_iscom, setPermissionsIscom] = useState(false);
  const [connected_permissions_pigier, setPermissionsPigier] = useState(false);
  const [connected_permissions_supveto, setPermissionsSupveto] = useState(false);
  const [connected_permissions_zone01, setPermissionsZone01] = useState(false);
  const [connected_permissions_admin, setPermissionsAdmin] = useState(false);
  const [connected_permissions_csv, setPermissionsCsv] = useState(false);
  const [connected_permissions_stats, setPermissionsStats] = useState(false);
  const [connected_permissions_candidats, setPermissionsCandidats] = useState(false);
  const [connected_permissions_mandats, setPermissionsMandats] = useState(false);

  function getCookie(name) {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  useEffect(() => {
    const token = getCookie("token");
    if (token) {
      const user_token = decodeToken(token);
      setFirstName(user_token.first_name);
      setLastName(user_token.last_name);
      setId(user_token.id);
      setEmail(user_token.email);
      setInitials(user_token.initials);
      setPermissionsIscom(user_token.permissions[0] === "1");
      setPermissionsPigier(user_token.permissions[1] === "1");
      setPermissionsSupveto(user_token.permissions[2] === "1");
      setPermissionsZone01(user_token.permissions[3] === "1");
      setPermissionsAdmin(user_token.permissions[4] === "1");
      setPermissionsCsv(user_token.permissions[5] === "1" || user_token.permissions[4] === "1",);
      setPermissionsStats(user_token.permissions[6] === "1" || user_token.permissions[4] === "1",);
      setPermissionsCandidats(user_token.permissions[7] === "1" || user_token.permissions[4] === "1",);
      setPermissionsMandats(user_token.permissions[8] === "1" || user_token.permissions[4] === "1",);
    } else {
      if (_CancelNavigate) {
        console.log("no permissions found");
      } else {
        navigate('/prospects');
      }
    }
  }, [navigate]);

  return {
    connected_first_name,
    connected_last_name,
    connected_id,
    connected_email,
    connected_initials,
    connected_permissions_iscom,
    connected_permissions_pigier,
    connected_permissions_supveto,
    connected_permissions_zone01,
    connected_permissions_admin,
    connected_permissions_csv,
    connected_permissions_stats,
    connected_permissions_candidats,
    connected_permissions_mandats,
  };
}

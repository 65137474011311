import React, { useState, useEffect } from 'react';
import "../../style/private/CalendarInput.css"
import "../../style/private/CheckboxList.css"
import "../../style/private/ErrorReportPopup.css"
import "../../style/private/FilterOptions.css"
import "../../style/private/Header.css"
import "../../style/private/Historic.css"
import "../../style/private/Loader.css"
import "../../style/private/Logo.css"
import "../../style/private/ScoringComponent.css"
import "../../style/private/SMSSection.css"
import "../../style/private/TextFormatter.css"
import "../../style/private/ToggleSlider.css"
import "../../style/private/Popup.css"

import "../../style/private/Home.css"
import "../../style/private/Panel.css"
import "../../style/private/Update.css"

const Popup = ({ trigger, children, isOpen, buttonClass }) => {
  const [internalOpenState, setInternalOpenState] = useState(false);

  useEffect(() => {
    setInternalOpenState(isOpen);
  }, [isOpen]);

  const togglePopup = () => {
    setInternalOpenState(!internalOpenState);
  };

  return (
    <>
      <button onClick={togglePopup} className={buttonClass ? buttonClass : 'ui-button'}>{trigger}</button>
      {internalOpenState && (
        <div className="popup" onClick={togglePopup}>
          <div className="popup-inner">
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;

import React, { useState } from 'react';
import axios from 'axios';
import "./Folder.css"
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"

const PDFUploader = ({ id, file_type }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const MAX_FILE_SIZE = 60 * 1024 * 1024; // 60mb

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const fileName = file.name;
            const fileNamePattern = /^[a-zA-Z0-9_.-]+$/;
            const extensionParts = fileName.split('.');
            if (extensionParts.length > 2) {
                setSelectedFile(null);
                setFileError("Le nom du fichier n'est pas valide.");
                return;
            }
            const sqlInjectionPattern = /(\b(SELECT|UPDATE|DELETE|INSERT|DROP|ALTER|CREATE|EXEC|UNION|TRUNCATE|DECLARE|XP_|UNION)\b)/i;
            if (sqlInjectionPattern.test(fileName)) {
                setSelectedFile(null);
                setFileError("Le nom du fichier n'est pas valide.");
                return;
            }

            if (!fileNamePattern.test(fileName)) {
                setSelectedFile(null);
                setFileError("Le nom du fichier n'est pas valide.");
                return;
            }

            setSelectedFile(file);
            setFileError("");
        } else {
            setSelectedFile(null);
        }
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            console.log("No file");
            return;
        }
        if (selectedFile.size > MAX_FILE_SIZE) {
            console.log("Size too large");
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('id', id);
        formData.append('type', file_type);

        try {
            const response = await axios.post('/upload_folder', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error("There was an error uploading the file!", error);
        }
    };

    return (
        <>
            <div onClick={(e) => e.stopPropagation()}>
                <input className="formInput" type="file" accept=".jpg, .jpeg, .png, .pdf" onChange={handleFileChange} />
                {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
            </div>
            {selectedFile != null &&
                <button className="formInput" onClick={handleFileUpload}>Envoyer le fichier</button>
            }
        </>
    );
};

export default PDFUploader;

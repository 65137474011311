import { useEffect, useRef } from 'react';

import { withInitialData } from './containers/withDataFetching';
import { SaveData } from './data/dataService';
import useDataReducer from './Hook/useDataReducer';

import Civility from './Components/civility/civlity';
import Contact from './Components/contact/contact';
import Diplome from './Components/diplome/diplome';
import Parent1 from './Components/Parent/Parent_1';
import Parent2 from './Components/Parent/Parent_2';
import Formation from './Components/formation/Formation';
import Scolarite from './Components/Scolarite/scolarite';
import Langue from './Components/Langue/Langue';
import InformationAnnexe from './Components/InformationAnnexe/InformationAnnexe';

import './Form_Iscom-pc.css'

const Form_Iscom = ({ folder_id, initialState }) => {
    const [state, dispatch] = useDataReducer(initialState)

    const SaveTimeout = useRef(null);

    useEffect(() => {
        if (SaveTimeout.current) {
            clearTimeout(SaveTimeout.current);
        }

        SaveTimeout.current = setTimeout(() => {
            SaveData(state, folder_id);
        }, 3000);

        return () => {
            clearTimeout(SaveTimeout.current);
        };
    }, [state]);

    return (
        <div className='Form-container'>
            <Civility state={state} dispatch={dispatch} />

            <Formation state={state} dispatch={dispatch} />

            <Diplome state={state} dispatch={dispatch} />

            <Contact state={state} dispatch={dispatch} />

            <Parent1 state={state} dispatch={dispatch} />

            <Parent2 state={state} dispatch={dispatch} />

            <Scolarite state={state} dispatch={dispatch} />

            <Langue state={state} dispatch={dispatch} />

            <InformationAnnexe state={state} dispatch={dispatch} />

        </div>
    )
};

export default withInitialData(Form_Iscom);
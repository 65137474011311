import React from 'react'

const Parent1 = ({ state, dispatch }) => {
    return (
        <div className='classic-container Card'>
            <h3>Parent 1</h3>
            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="nomparent">Nom du parent :</label>
                    <input
                        type="text"
                        id="nomparent"
                        placeholder="Doe"
                        value={state.NomParent1}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "NomParent1",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="prenomparent">Prénom du parent :</label>
                    <input
                        type="text"
                        id="prenomparent"
                        placeholder="John"
                        value={state.PrenomParent1}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "PrenomParent1",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="professionparent">Profession du parent :</label>
                    <input
                        type="text"
                        id="professionparent"
                        placeholder="Profession"
                        value={state.ProfessionParent1}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "ProfessionParent1",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="entrepriseparent">Entreprise du parent :</label>
                    <input
                        type="text"
                        id="entrepriseparent"
                        placeholder="Entreprise"
                        value={state.EntrepriseParent1}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "EntrepriseParent1",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="emailparent">Email du parent :</label>
                    <input
                        type="text"
                        id="emailparent"
                        placeholder="email@example.com"
                        value={state.EmailParent1}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "EmailParent1",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default Parent1
export function formatDate(dateInput, format) {
  let date;
  if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else if (dateInput instanceof Date) {
    date = dateInput;
  }
  const options = {
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    hour12: false,
    timeZone: 'Europe/Paris'
  };

  const formatter = new Intl.DateTimeFormat('fr-FR', options);
  const parts = formatter.formatToParts(date);

  const dateMap = {};
  parts.forEach(part => {
    dateMap[part.type] = part.value;
  });

  const formatMap = {
    'YYYY': dateMap.year,
    'MM': dateMap.month,
    'DD': dateMap.day,
    'HH': dateMap.hour,
    'mm': dateMap.minute,
    'ss': dateMap.second,
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, matched => formatMap[matched]);
}


export function generateRandomPassword(size) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=<>?';
  let randomPassword = '';
  for (let i = 0; i < size; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomPassword += characters.charAt(randomIndex);
  }

  return randomPassword;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Required from "../global/SVG_required";
import FolderHeader from "./Folder_Header";
import { useAuth } from "../hooks/useAuth";
import FolderAccess from "./FolderAccess";
import Popup from "../prospect/components/Popup";
import PDFUploader from "./PDF_Uploader";
import FormIscom from "./Form_Iscom/Form_Iscom";

const FolderIscom = () => {
    const { folder_id } = useParams();
    const [folder, setFolder] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const { connected_id } = useAuth(true);
    const handleLoginSuccess = async () => {
        const id = folder_id;
        if (
            connected_id == null ||
            connected_id === undefined ||
            connected_id === ""
        ) {
            try {
                await axios.put(`/update-folder-last-conn/${id}`);
            } catch (error) {
                console.error(
                    "Erreur lors de la mise à jour de la dernière connexion :",
                    error,
                );
            }
        }
        setIsConnected(true);
    };
    const handleFetch = async () => {
        try {
            const response = await axios.get(`/get-folder-id/${folder_id}`);
            setFolder(response.data.values[0]);
        } catch (err) {
            setFolder(null);
        }
    };
    useEffect(() => {
        handleFetch();
    }, []);
    return (
        <>
            {isConnected ? (
                <></>
            ) : (
                <FolderAccess onLoginSuccess={handleLoginSuccess} />
            )}
            {isConnected && folder != null ? (
                <>
                    <FolderHeader
                        student_name={folder.folder_student_name}
                        folder_id={folder.folder_id}
                    ></FolderHeader>
                    <h1 className="title-historic">Dossier de candidature :</h1>

                    <FormIscom folderID={folder_id} />

                    <h1 className="title-historic">Documents à fournir : 0/6</h1>
                    <div className='folder_files_container'>
                        <Popup trigger={
                            <div>
                                <h5><Required />Carte nationale d'identité :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Carte nationale d'identité</h1>
                            <p className='folder_files_instructions'>Instructions</p>
                            <ul className='folder_files_info'>
                                <li>Scan ou photo de la carte d'identité.</li>
                                <li>Côté recto / verso côte à côte ou a la suite.</li>
                            </ul>
                            <PDFUploader id={folder_id} file_type={"CARTE_IDENTITE"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Photo d'identité :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Photo d'identité</h1>
                            <p className='folder_files_instructions'>Instructions</p>
                            <ul className='folder_files_info'>
                                <li>Photo d'identité récente.</li>
                            </ul>
                            <PDFUploader id={folder_id} file_type={"PHOTO_IDENTITE"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />CV :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> CV</h1>
                            <PDFUploader id={folder_id} file_type={"CV"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Lettre de motivation :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Lettre de motivation</h1>
                            <PDFUploader id={folder_id} file_type={"LETTRE_MOTIVATION"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Bulletins Scolaires (2nde à terminale) :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Bulletins Scolaires (2nde à terminale)</h1>
                            <PDFUploader id={folder_id} file_type={"BULLETINS_LYCEE"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Bulletins Scolaires (PostBac) :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Bulletins Scolaires (PostBac)</h1>
                            <PDFUploader id={folder_id} file_type={"BULLETINS_POST_BAC"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Relevés de note des 3 dernières années :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Relevés de note des 3 dernières années</h1>
                            <PDFUploader id={folder_id} file_type={"NOTES"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Diplômes obtenus après le BAC :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Relevés de note des 3 dernières années</h1>
                            <p className='folder_files_instructions'>Instructions</p>
                            <ul className='folder_files_info'>
                                <li>Pour les diplômes étrangers, merci de fournir une attestation de comparabilité via le centre ENIC-NARIC</li>
                                <li> vous pouvez en faire la demande via ce lien : <a href="https://phoenix.france-education-international.fr/inscriptions/">&nbsp;France education International</a></li>
                            </ul>
                            <PDFUploader id={folder_id} file_type={"DIPLOME_POST_BAC"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Certification de langue française :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Certification de langue française :</h1>
                            <PDFUploader id={folder_id} file_type={"CERTIFICATION_LANGUE_FRANCAISE"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Production créatives :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Production créatives :</h1>
                            <PDFUploader id={folder_id} file_type={"PRODUCTION_CREATIVES"}></PDFUploader>
                        </Popup>
                        <Popup trigger={
                            <div>
                                <h5><Required />Attestation RQTH :</h5>
                                <div className='folder'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                                </div>
                            </div>
                        } buttonClass="formInput">
                            <h1><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="48" height="48"><rect fill="none" /><line x1="152" y1="112" x2="192" y2="112" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><line x1="152" y1="144" x2="192" y2="144" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><circle cx="92.1" cy="120" r="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><path d="M61.1,168a32,32,0,0,1,62,0" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /><rect x="32" y="48" width="192" height="160" rx="8" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" /></svg> Attestation RQTH :</h1>
                            <PDFUploader id={folder_id} file_type={"ATTESTATION_RQTH"}></PDFUploader>
                        </Popup>
                    </div>
                    <button className="login-button">Finaliser la candidature</button>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default FolderIscom;

import React, { useState } from 'react';
import pigier from "../assets/pigier.png";
import iscom from "../assets/iscom.png";
import zone01 from "../assets/zone01.png";
import supveto from "../assets/supveto.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidEmail } from '../utils';

import "./Folder.css"
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"

const FolderConnect = () => {
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');
    const [folders, setFolders] = useState(null);
    const [folder_name, setFolderName] = useState('');
    const navigate = useNavigate();
    const handleState = (state_name) => {
        if (state_name === "connect" && !isValidEmail(email)) { return }
        setState(state_name);
    };

    const handleChangeMail = (e) => {
        setEmail(e.target.value);
    };

    const handleFolder = (folderName) => {
        fetchFolder(folderName);
        setFolderName(folderName);
    };

    const handleCreate = async () => {
        try {
            //response deleted bc isn't use
            await axios.post('/create-folder', {
                folder_type: folder_name,
                student_email: email,
                student_name: email
            });
            handleState("success")
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };

    const fetchFolder = async (folderName) => {
        if (!email) {
            console.error("Email is empty");
            return;
        }
        try {
            const response = await axios.post('/get-folder-email', { email, folder_name: folderName });
            setFolders(response.data.values[0]);
            navigate(`/candidats/${response.data.values[0].folder_type}/folder/${response.data.values[0].folder_id}`);
        } catch (err) {
            console.error("Error fetching folder:", err);
            setFolders(null);
        }
    };

    const folderImages = {
        pigier,
        iscom,
        zone01,
        supveto
    };

    return (
        <>
            <div className="background-container"></div>
            <div className='connect-container'>
                {state === '' && (
                    <div>
                        <input className='connect-input' placeholder="email@exemple.com" type='email' onBlur={handleChangeMail} />
                        <button className="connect-button" onClick={() => handleState("connect")}>Se connecter</button>
                    </div>
                )}
                {state === 'connect' && (
                    <div>
                        <button className="connect-button" onClick={() => { handleState("access"); handleFolder('iscom'); }}>Iscom</button>
                        <button className="connect-button" onClick={() => { handleState("access"); handleFolder('pigier'); }}>Pigier</button>
                        <button className="connect-button" onClick={() => { handleState("access"); handleFolder('supveto'); }}>Supveto</button>
                        <button className="connect-button" onClick={() => { handleState("access"); handleFolder('zone01'); }}>Zone01</button>
                        <button className="connect-button" onClick={() => handleState("")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16" height="16" fill='currentColor'><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg> Retour</button>
                    </div>
                )}
                {state === 'access' && (
                    <div>
                        {folders !== null ? (
                            <p>En attente</p>
                        ) : (
                            <>
                                <img src={folderImages[folder_name]} alt={folder_name} className='connect-logo' />
                                <p>Pour récupérer vos accès, cliquez sur le bouton ci-dessous.</p>
                                <p>Vous allez recevoir un code de connexion et un lien d'accès sur le mail que vous avez entré. (<strong>{email}</strong>)</p>
                                <button className="connect-button" onClick={handleCreate}>Recevoir le code par mail.</button>
                            </>
                        )}
                        <button className="connect-button" onClick={() => handleState("connect")}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16" height="16" fill='currentColor'><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg> Retour</button>
                    </div>
                )}
                {state === 'success' && (
                    <div>
                        <p>L'email a été envoyer à {email}. Vous pouvez quitter cette page.</p>
                    </div>
                )}

            </div>
        </>
    );
};

export default FolderConnect;

import axios from "axios";
import { initialState, mapping } from "./dataReducer";

// function of auto save 
export const SaveData = async (state, folderID) => {
    try {
        await axios.post(`/update-folder/${folderID}`, state);
    } catch (error) {
        console.error(error)
    }
};

// function to get what already been saved
export const fetchInitialData = async (folderID) => {
    return axios
        .get(`/update-folder/${folderID}`)
        .then((response) => {
            if (response.status !== 200) {
                console.log("error to fetch Data", response, response.status)
            }

            let result = fillInitialState(
                response.data.values[0],
                initialState,
                mapping,
            );
            return result;
        })
        .catch((err) => {
            console.error(err);
            return initialState;
        });
};

// function to modify initial state with fetch value
export const fillInitialState = (fetchedData, initialState, mapping) => {
    return Object.keys(initialState).reduce((NewState, key) => {
        const apiKeys = Object.keys(mapping).find(apiKey => mapping[apiKey] === key);
        if (apiKeys) {
            const value = fetchedData[apiKeys];
            if (apiKeys === "date_naissance") {
                NewState[key] = ConvertDate(value);
            } else if (apiKeys === "autre") {
                NewState["Autre"] = value !== "" ? true : false;
                NewState[key] = value;
            } else {
                NewState[key] = value;
            }
        } else {
            NewState[key] = initialState[key];
        }
        return NewState;
    }, {});
};

// function to convert the date with DB format to input format
export const ConvertDate = (Date) => {
    if (Date === "") return "";
    let CutDate = "";
    let FormatedDate;
    let Stop = false;

    for (let i = 0; i < Date.length; i++) {
        if (!Stop && Date[i] === "T") {
            Stop = true;
            break;
        }
        CutDate += Date[i];
    }

    let SliceDate = CutDate.split("-");

    SliceDate[2] = (parseInt(SliceDate[2], 10) + 1).toString();

    FormatedDate = `${SliceDate[0]}-${SliceDate[1]}-${SliceDate[2]}`;
    return FormatedDate;
};

// function to fetch Api to get Adresse suggestion
export const FetchAdresse = async (value) => {
    if (value.length > 5) {
        try {
            const response = await axios.get(
                `https://api-adresse.data.gouv.fr/search/?q=${value}`,
            );

            if (response.status !== 200) {
                console.error("Erreur lors de la recherche d'adresses", response);
                return [];
            }

            let result = response.data.features;

            return result;
        } catch (error) {
            console.error("Erreur lors de la recherche d'adresses", error);
            return [];
        }
    } else {
        return [];
    }
};

//function to fetch Date of Concours
export const fetchConcours = async () => {
    try {
        const response = await axios.get("/get-concours");
        if (response.status !== 200) {
            console.error("Erreur lors de la récupération des concours", response);
            return [];
        }
        return response.data.values
    } catch (error) {
        console.log(error);
        return [];
    }
};

//function to map data into object for concours
export const MapConcours = (values) => {
    let ConcourData = values.map((item) => ({
        label: item.name,
        value: item.name,
    }));
    ConcourData.unshift({ label: "Aucune valeur", value: "" });
    return ConcourData;
};

//function fo fetch Formations
export const fetchFormations = async () => {
    try {
        const response = await axios.get("/get-formations");
        if (response.status !== 200) {
            console.error("Error fetching formations:", response);
            return [];
        }
        return response.data.values
    } catch (error) {
        console.error("Error fetching formations:", error);
        return [];
    }
};

//function to map and sort data into object for formation
export const MapFormation = (values) => {
    const sortedFormations = values.sort(
        (a, b) => a.order - b.order,
    );
    let FormationData = sortedFormations.map((obj) => ({
        label: obj.name,
        value: obj.id,
    }));
    FormationData.unshift({ label: "Aucune valeur", value: "" });
    return FormationData;
};


import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem('token');

  if (!token) {
    // Save the current path (event link) in local storage or as a query parameter
    localStorage.setItem('redirectAfterLogin', location.pathname);
    // If no token is found, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If token is found, allow access to the protected route
  return children;
};

export default ProtectedRoute;

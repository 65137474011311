import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import { formatDate } from '../utils';
import "./Folder.css"
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"

const MessageComponent = ({ student_name, folder_id }) => {
    const [showMessage, setShowMessage] = useState(false);
    const [message_content, setMessageContent] = useState('');
    const [messages, setMessages] = useState([]);
    const { connected_first_name, connected_last_name, connected_id } = useAuth(true);
    const IS_STAFF = connected_id != null && connected_id !== undefined && connected_id !== ''
    useEffect(() => {
        let intervalId;

        if (showMessage) {
            fetchMessages();
            intervalId = setInterval(fetchMessages, 5000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [showMessage]);

    const fetchMessages = async () => {
        const id = folder_id;
        try {
            const response = await axios.get(`/get-message-id/${id}`);
            setMessages(response.data.values);
            if (messages.length !== response.data.values.length) {
                const container = document.getElementById('message_list_container');
                setTimeout(() => {
                    container.scrollTop = container.scrollHeight;
                }, 100);
            }
            try {
                //response delete bc isn't use
                await axios.put('/update-message', { id: id, author: connected_first_name + " " + connected_last_name });
            } catch (error) { }
        } catch (err) {
            setMessages(null);
        }
    };

    const create_message = async (message_content) => {
        if (message_content.length < 2) { return }
        setMessageContent('');
        let name = connected_first_name && connected_last_name ? `${connected_first_name} ${connected_last_name}` : student_name;
        const timestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');

        try {
            //response delete bc isn't use
            await axios.post('/create-message', { folder_id, message_author: name, message_content, message_staff: IS_STAFF, message_date: timestamp });
            fetchMessages();
        } catch (error) {
            console.error('Error creating action:', error);
        }
    };

    const handleShowMessage = () => {
        setShowMessage(!showMessage);
        fetchMessages();
    };

    const handleMessageContent = (e) => {
        setMessageContent(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            create_message(message_content)
        }
    }

    useEffect(() => {
        fetchMessages();
    }, []);

    return (
        <div>
            <div className='message_show_trigger' onClick={handleShowMessage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                </svg>
            </div>
            {showMessage && (
                <div className='message_popup'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={handleShowMessage} width="16" height="16" fill="currentColor" className="bi bi-x-lg close_message" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" /> <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" /> </svg>
                    </div>
                    {messages && messages.length > 0 ? (
                        <div className='message_list_container' id="message_list_container">
                            {messages.map((message) => (
                                <div className={`message_container ${message.message_author === student_name ? 'message_author_student' : 'message_author_admin'}`} key={message.message_id}>
                                    <div>
                                        <span className='message_author'>{message.message_author || "Inconnu"}</span>
                                        <span className='message_date'>{formatDate(message.message_date, 'YYYY-MM-DD HH:mm:ss') || "Date inconnue"}</span>
                                    </div>
                                    <p className={`message_content ${message.message_author === student_name ? 'message_content_student' : 'message_content_admin'}`}>{message.message_content || ""}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className='message_date'>Aucun nouveau message</p>
                    )}
                    <div className='message_send_section'>
                        <input type='text' placeholder='Envoyer un message' className='message_input' onChange={handleMessageContent} onKeyDown={(e) => { handleKeyPress(e) }} value={message_content} />
                        <button onClick={() => create_message(message_content)} className='message_sender'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessageComponent;
import React from 'react'

const Scolarite = ({ state, dispatch }) => {
    return (
        <>
            <table className="checkbox-table">
                <thead>
                    <tr>
                        <th>Section</th>
                        <th>Nom de Formation/Section</th>
                        <th>Établissement: nom et ville</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            Seconde
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_Seconde"
                                value={state.formation_Seconde}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_Seconde",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>

                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_Seconde"
                                value={state.etablissement_Seconde}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_Seconde",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            Première
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_Première"
                                value={state.formation_Premiere}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_Premiere",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_Première"
                                value={state.etablissement_Premiere}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_Premiere",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            Terminale
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_Terminale"
                                value={state.formation_Terminale}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_Terminale",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_Terminale"
                                value={state.etablissement_Terminale}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_Terminale",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            1ère année
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_1ère année"
                                value={state.formation_1a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_1a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_1ère année"
                                value={state.etablissement_1a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_1a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            2ème année
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_2ème année"
                                value={state.formation_2a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_2a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_2ème année"
                                value={state.etablissement_2a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_2a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            3ème année
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_3ème année"
                                value={state.formation_3a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_3a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_3ème année"
                                value={state.etablissement_3a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_3a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="en-tete" data-label="Année">
                            4ème année
                        </td>
                        <td className="THInput" data-label="Nom de Formation/Section">
                            <input
                                type="text"
                                className="text-input"
                                name="formation_4ème année"
                                value={state.formation_4a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "formation_4a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                        <td className="THInput" data-label="Établissement: nom et ville">
                            <input
                                type="text"
                                className="text-input"
                                name="etablissement_4ème année"
                                value={state.etablissement_4a}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "etablissement_4a",
                                        value: e.target.value,
                                    });
                                }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default Scolarite
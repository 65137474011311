import React, { useEffect, useRef } from 'react'
import { FetchAdresse } from "../../data/dataService"

const Contact = ({ state, dispatch }) => {
    const initialRender = useRef(true);
    const VilleRef = useRef(null)
    const AdresseRef = useRef(null)
    const CodePostalRef = useRef(null)

    const borderPropos = {
        border:
            state.adresseSuggestions.length > 0
                ? "1px solid var(--gris-clair)"
                : "none",
    };

    const handleMultipleUpdate = (fields) => {
        dispatch({ type: "SET_FIELDS", fields });
    };

    const handleSelectAddress = (selected) => {
        const { city, name, postcode } = selected.properties;
        VilleRef.current.value = city;
        AdresseRef.current.value = name;
        CodePostalRef.current.value = postcode;
        handleMultipleUpdate({ adresse: name, ville: city, codepostal: postcode });
        dispatch({ type: "SET_FIELD", field: "adresseSuggestions", value: [] });
    };

    const UpdateSuggestion = async () => {
        let resultSuggestion = []
        await FetchAdresse(state.adresse + " " + state.ville + " " + state.codepostal)
            .then((result) => {
                resultSuggestion = result;
            })
        dispatch({ type: "SET_FIELD", field: "adresseSuggestions", value: resultSuggestion });
    };

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            UpdateSuggestion();
        }
    }, [state.adresse, state.ville, state.codepostal])



    return (
        <div className='classic-container Card'>
            <h3>Contact</h3>
            <ul className="Proposition" style={borderPropos}>
                {state.adresseSuggestions.map((suggestion, index) => {
                    return (
                        <li key={index} onClick={() => handleSelectAddress(suggestion)}>
                            {suggestion.properties.label}
                        </li>
                    );
                })}
            </ul>

            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="adresse">Adresse :</label>
                    <input
                        type="text"
                        id="adresse"
                        placeholder="123 Main St"
                        ref={AdresseRef}
                        value={state.adresse}
                        onChange={(e) => dispatch({ type: "SET_FIELD", field: "adresse", value: e.target.value })}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="ville">Ville :</label>
                    <input
                        type="text"
                        id="ville"
                        placeholder="Springfield"
                        ref={VilleRef}
                        value={state.ville}
                        onChange={(e) => dispatch({ type: "SET_FIELD", field: "ville", value: e.target.value })}
                    />
                </div>
            </div>

            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="codepostal">Code Postal :</label>
                    <input
                        type="number"
                        id="codepostal"
                        placeholder="62701"
                        ref={CodePostalRef}
                        value={state.codepostal}
                        onChange={(e) => dispatch({ type: "SET_FIELD", field: "codepostal", value: e.target.value })}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="pays">Pays :</label>
                    <input
                        type="text"
                        id="pays"
                        placeholder="France"
                        value={state.pays}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "pays",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
            </div>

            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="telfix">Tél. fixe :</label>
                    <input
                        type="text"
                        id="telfix"
                        placeholder="02 13 45 67 89"
                        value={state.TelFix}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "TelFix",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="tel">Tél :</label>
                    <input
                        type="text"
                        id="tel"
                        placeholder="06 12 34 56 78"
                        value={state.Tel}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "Tel",
                                value: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default Contact;
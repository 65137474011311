import { useState, useEffect } from "react";
import { fetchInitialData } from "../data/dataService";

export const withInitialData = (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    return ({ folderID }) => {
        const [initialData, setInitialData] = useState(null);

        useEffect(() => {
            const initializeData = async () => {
                const fetchedData = await fetchInitialData(folderID);
                console.log(fetchedData)
                setInitialData(fetchedData);
            };

            initializeData();
        }, [folderID]);

        if (!initialData) {
            return <div>Loading...</div>;
        }

        return (
            <WrappedComponent
                folder_id={folderID}
                initialState={initialData}
            />
        );
    };
};
export const initialState = {
    FormationType: "",
    civility: "",
    nom: "",
    prenom: "",
    email: "",
    Date_de_naissance: "",
    adresseSuggestions: [],
    adresse: "",
    ville: "",
    codepostal: "",
    pays: "France",
    TelFix: "",
    Tel: "",
    BacValider: null,
    DernierDiplome: "",
    FormationActuelle: "",
    NomEtablissement: "",
    VilleEtablissement: "",
    NomParent1: "",
    PrenomParent1: "",
    EmailParent1: "",
    ProfessionParent1: "",
    EntrepriseParent1: "",
    NomParent2: "",
    PrenomParent2: "",
    EmailParent2: "",
    ProfessionParent2: "",
    EntrepriseParent2: "",
    Concours: [],
    ChooseConcours: "",
    Formations: [],
    ChooseFormation: "",
    AnneeEtude: "",
    connuepar: [],
    boucheaoreille: 0,
    jpo: 0,
    internet: 0,
    salon: 0,
    Autre: false,
    AutreValue: "",
    autreinformation: "",
    formation_Seconde: "",
    etablissement_Seconde: "",
    formation_Premiere: "",
    etablissement_Premiere: "",
    formation_Terminale: "",
    etablissement_Terminale: "",
    formation_1a: "",
    etablissement_1a: "",
    formation_2a: "",
    etablissement_2a: "",
    formation_3a: "",
    etablissement_3a: "",
    formation_4a: "",
    etablissement_4a: "",
    langue_Vivante1: "",
    langue_vivante1_ecrit: "",
    langue_vivante1_oral: "",
    langue_Vivante2: "",
    langue_vivante2_ecrit: "",
    langue_vivante2_oral: "",
    sejourlinguistiques: "",
};

export const mapping = {
    civilite: "civility",
    nom: "nom",
    prenom: "prenom",
    email: "email",
    date_naissance: "Date_de_naissance",
    adresse: "adresse",
    ville: "ville",
    code_postal: "codepostal",
    pays: "pays",
    tel_fixe: "TelFix",
    tel_portable: "Tel",
    bac_valide: "BacValider",
    dernier_diplome_valide: "DernierDiplome",
    formation_actuelle_en_cours: "FormationActuelle",
    nom_etablissement: "NomEtablissement",
    ville_etablissement: "VilleEtablissement",
    parent1_nom: "NomParent1",
    parent1_prenom: "PrenomParent1",
    parent1_email: "EmailParent1",
    parent1_profession: "ProfessionParent1",
    parent1_entreprise: "EntrepriseParent1",
    parent2_nom: "NomParent2",
    parent2_prenom: "PrenomParent2",
    parent2_email: "EmailParent2",
    parent2_profession: "ProfessionParent2",
    parent2_entreprise: "EntrepriseParent2",
    date_choisie: "ChooseConcours",
    annee_etude_choisie: "AnneeEtude",
    programme_choisi: "ChooseFormation",
    broucheaoreille: "boucheaoreille",
    jpo: "jpo",
    internet: "internet",
    salon: "salon",
    autre: "AutreValue",
    autreinformation: "autreinformation",
    langue_vivante1: "langue_Vivante1",
    langue_vivante1_ecrit: "langue_vivante1_ecrit",
    langue_vivante1_oral: "langue_vivante1_oral",
    langue_vivante2: "langue_Vivante2",
    langue_vivante2_ecrit: "langue_vivante2_ecrit",
    langue_vivante2_oral: "langue_vivante2_oral",
    sejours_linguistiques: "sejourlinguistiques",
    formation_seconde: "formation_Seconde",
    formation_premiere: "formation_Premiere",
    formation_terminal: "formation_Terminale",
    etablissement_seconde: "etablissement_Seconde",
    etablissement_premiere: "etablissement_Premiere",
    etablissement_terminal: "etablissement_Terminale",
    formation_1a: "formation_1a",
    formation_2a: "formation_2a",
    formation_3a: "formation_3a",
    formation_4a: "formation_4a",
    etablissement_1a: "etablissement_1a",
    etablissement_2a: "etablissement_2a",
    etablissement_3a: "etablissement_3a",
    etablissement_4a: "etablissement_4a",
};

export const dataReducer = (state, action) => {
    switch (action.type) {
        case "SET_FIELD":
            return {
                ...state,
                [action.field]: action.value,
            };
        case "SET_FIELDS":
            return {
                ...state,
                ...action.fields,
            };
        default:
            return state;
    }
};
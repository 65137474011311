import React from 'react'

const Langue = ({ state, dispatch }) => {
    return (
        <div className='classic-container Card Langue'>
            <h3>Langue</h3>

            <div className="langue-wrapper column">
                <div className="langue-container">
                    <label htmlFor="langue">Langue Vivante 1 :</label>
                    <input type="text" value={state.langue_Vivante1} onChange={(e) => { dispatch({ type: "SET_FIELD", field: "langue_Vivante1", value: e.target.value }) }} />
                </div>
                <div className="langue-wrapper">
                    <div className="langue-container">
                        <label htmlFor="niveau">Écrit :</label>
                        <select
                            className="Selection"
                            id="niveau"
                            value={state.langue_vivante1_ecrit}
                            onChange={(e) => {
                                dispatch({ type: "SET_FIELD", field: "langue_vivante1_ecrit", value: e.target.value })
                            }}
                        >
                            <option value="" hidden>
                                Choisissez
                            </option>
                            <option value="debutant">Débutant</option>
                            <option value="intermediaire">Intermédiaire</option>
                            <option value="avance">Avancé</option>
                        </select>
                    </div>
                    <div className="langue-container">
                        <label htmlFor="niveau">Oral :</label>
                        <select
                            className="Selection"
                            id="niveau"
                            value={state.langue_vivante1_oral}
                            onChange={(e) => {
                                dispatch({ type: "SET_FIELD", field: "langue_vivante1_oral", value: e.target.value })
                            }
                            }
                        >
                            <option value="" hidden>
                                Choisissez
                            </option>
                            <option value="debutant">Débutant</option>
                            <option value="intermediaire">Intermédiaire</option>
                            <option value="avance">Avancé</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="langue-wrapper column">
                <div className="langue-container">
                    <label htmlFor="langue">Langue Vivante 2 :</label>
                    <input type="text" value={state.langue_Vivante2} onChange={(e) => { dispatch({ type: "SET_FIELD", field: "langue_Vivante2", value: e.target.value }) }} />
                </div>

                <div className="langue-wrapper">
                    <div className="langue-container">
                        <label htmlFor="niveau">Écrit :</label>
                        <select
                            className="Selection"
                            id="niveau"
                            value={state.langue_vivante2_ecrit}
                            onChange={(e) => {
                                dispatch({ type: "SET_FIELD", field: "langue_vivante2_ecrit", value: e.target.value })
                            }}
                        >
                            <option value="" hidden>
                                Choisissez
                            </option>
                            <option value="debutant">Débutant</option>
                            <option value="intermediaire">Intermédiaire</option>
                            <option value="avance">Avancé</option>
                        </select>
                    </div>
                    <div className="langue-container">
                        <label htmlFor="niveau">Oral :</label>
                        <select
                            className="Selection"
                            id="niveau"
                            value={state.langue_vivante2_oral}
                            onChange={(e) => {
                                dispatch({ type: "SET_FIELD", field: "langue_vivante2_oral", value: e.target.value })
                            }}
                        >
                            <option value="" hidden>
                                Choisissez
                            </option>
                            <option value="debutant">Débutant</option>
                            <option value="intermediaire">Intermédiaire</option>
                            <option value="avance">Avancé</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="langue-container Full-size">
                <div className="langue-container Full-size">
                    <label htmlFor="sejourlinguistiques">Sejour Linguistique :</label>
                    <textarea id='sejourlinguistiques' type="text" rows={"4"} value={state.sejourlinguistiques} onChange={(e) => {
                        dispatch({ type: 'SET_FIELD', field: 'sejourlinguistiques', value: e.target.value })
                    }} />
                </div>
            </div>

        </div >
    )
}

export default Langue;
import React, { useEffect } from 'react'
import { fetchConcours, MapConcours, fetchFormations, MapFormation } from '../../data/dataService';

const Formation = ({ state, dispatch }) => {

    const GetConcoursData = async () => {
        let concoursBrutData = [];
        await fetchConcours()
            .then((data) => {
                concoursBrutData = data;
            })
        let concoursData = MapConcours(concoursBrutData);
        dispatch({ type: "SET_FIELD", field: "Concours", value: concoursData });
    }

    const GetFormationData = async () => {
        let formationBrutData = [];
        await fetchFormations()
            .then((data) => {
                formationBrutData = data;
            })
        let formationData = MapFormation(formationBrutData);
        dispatch({ type: "SET_FIELD", field: "Formations", value: formationData });
    }

    useEffect(() => {
        GetConcoursData();
        GetFormationData();
    }, [])

    const FormatConcourDate = (value) => {
        let space = false;
        let date = "";
        for (let index in value) {
            if (!space && value[index] === " ") {
                break;
            }
            date += value[index];
        }
        let SplitedDate = date.split("/");
        let result = `${SplitedDate[2]}-${SplitedDate[1]}-${SplitedDate[0]}`;
        return result;
    };



    return (
        <div className='classic-container Card'>
            <h3>Formation souhaitée</h3>
            <div className="inputs-wrapper">
                <div className="input-container">

                    <label htmlFor="formation">Formation choisie</label>
                    <select
                        className="Selection"
                        id="formation"
                        value={state.ChooseFormation}
                        onChange={(e) => {
                            dispatch({
                                type: "SET_FIELD",
                                field: "ChooseFormation",
                                value: e.target.value,
                            });
                        }}
                    >
                        {state.Formations.map((item, index) => {
                            return (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div className="input-container">
                    <label htmlFor="annee">Année d&apos;études choisie</label>
                    <select
                        className="Selection"
                        value={state.AnneeEtude}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "AnneeEtude",
                                value: e.target.value,
                            })
                        }
                    >
                        <option value="" disabled hidden selected>
                            Choisissez
                        </option>
                        <option value="1ère année">1ère année</option>
                        <option value="2ème année">2ème année</option>
                        <option value="3ème année">3ème année</option>
                        <option value="4ème année">4ème année</option>
                        <option value="5ème année">5ème année</option>
                    </select>
                </div>
            </div>
            <div className="inputs-wrapper">
                <div className="input-container WithGap">
                    <label htmlFor="concours">Concours :</label>
                    <select
                        className="Selection"
                        id="concours"
                        value={state.ChooseConcours}
                        onChange={(e) => {
                            dispatch({
                                type: "SET_FIELD",
                                field: "ChooseConcours",
                                value: e.target.value,
                            });
                        }}
                    >
                        {state.Concours.map((item, index) => {
                            return (
                                <option key={index} value={FormatConcourDate(item.value)}>
                                    {item.label}
                                </option>
                            );
                        })}
                    </select>

                    {/* <div className="inputs-container">
                        <div className="TextArea">
                            <textarea
                                id="textarea"
                                disabled={!state.Autre}
                                style={{ cursor: !state.Autre ? "not-allowed" : "text", background: !state.Autre ? "var(--gris-clair)" : "none" }}
                                value={state.AutreValue}
                                onChange={(e) => {
                                    if (state.Autre === true) {
                                        dispatch({
                                            type: "SET_FIELD",
                                            field: "AutreValue",
                                            value: e.target.value,
                                        });
                                    }
                                }}
                            ></textarea>
                        </div>
                    </div> */}
                </div>
                {/* <div className="checkbox-wrapper">
                    <label className="custom-checkbox" htmlFor="bouche a oreille">
                        <input
                            type="checkbox"
                            name="custom"
                            value={"Bouche à oreille"}
                            id="bouche a oreille"
                            checked={IsChecked(state.boucheaoreille)}
                            onChange={(e) => {
                                if (e.target.checked)
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "boucheaoreille",
                                        value: true,
                                    });
                                else
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "boucheaoreille",
                                        value: false,
                                    });
                            }}
                        />
                        <span></span>
                        Bouche à oreille
                    </label>

                    <label className="custom-checkbox" htmlFor="JPO">
                        <input
                            type="checkbox"
                            name="custom"
                            value={"JPO"}
                            id="JPO"
                            checked={IsChecked(state.jpo)}
                            onChange={(e) => {
                                if (e.target.checked)
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "jpo",
                                        value: true,
                                    });
                                else
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "jpo",
                                        value: false,
                                    });
                            }}
                        />
                        <span></span>
                        Journée Portes Ouvertes
                    </label>

                    <label className="custom-checkbox" htmlFor="internet">
                        <input
                            type="checkbox"
                            name="custom"
                            value={"Internet"}
                            id="internet"
                            checked={IsChecked(state.internet)}
                            onChange={(e) => {
                                if (e.target.checked)
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "internet",
                                        value: true,
                                    });
                                else
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "internet",
                                        value: false,
                                    });
                            }}
                        />
                        <span></span>
                        Internet
                    </label>

                    <label className="custom-checkbox" htmlFor="salon">
                        <input
                            type="checkbox"
                            name="custom"
                            value={"Salon"}
                            id="salon"
                            checked={IsChecked(state.salon)}
                            onChange={(e) => {
                                if (e.target.checked)
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "salon",
                                        value: true,
                                    });
                                else
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "salon",
                                        value: false,
                                    });
                            }}
                        />
                        <span></span>
                        Salon
                    </label>

                    <label className="custom-checkbox" htmlFor="autre">
                        <input
                            type="checkbox"
                            name="custom"
                            id="autre"
                            checked={state.Autre}
                            onChange={() => {
                                dispatch({
                                    type: "SET_FIELD",
                                    field: "Autre",
                                    value: !state.Autre,
                                });
                            }}
                        />
                        <span></span>
                        Autre
                    </label>
                </div> */}

                {/* <Checkbox state={state} dispatch={dispatch} /> */}


            </div>

        </div>
    )
}

export default Formation
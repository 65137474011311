import React from 'react'

const InformationAnnexe = ({ state, dispatch }) => {

    const IsChecked = (checkbox) => {
        return checkbox === 1 ? true : false;
    }

    return (
        <div className="classic-container Card plus-info">
            <h3>Information Annexe</h3>

            <div className="Info-container">

                <div className="ConnuePar">
                    <label>Comment avez-vous connu l'ISCOM ?</label>
                    <div className="checkbox-wrapper wrapping">
                        <label className="custom-checkbox" htmlFor="bouche a oreille">
                            <input
                                type="checkbox"
                                name="custom"
                                value={"Bouche à oreille"}
                                id="bouche a oreille"
                                checked={IsChecked(state.boucheaoreille)}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "boucheaoreille",
                                        value: e.target.checked === true ? 1 : 0,
                                    });
                                }}
                            />
                            <span></span>
                            Bouche à oreille
                        </label>

                        <label className="custom-checkbox" htmlFor="JPO">
                            <input
                                type="checkbox"
                                name="custom"
                                value={"JPO"}
                                id="JPO"
                                checked={IsChecked(state.jpo)}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "jpo",
                                        value: e.target.checked === true ? 1 : 0,
                                    });
                                }}
                            />
                            <span></span>
                            Journée Portes Ouvertes
                        </label>

                        <label className="custom-checkbox" htmlFor="internet">
                            <input
                                type="checkbox"
                                name="custom"
                                value={"Internet"}
                                id="internet"
                                checked={IsChecked(state.internet)}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "internet",
                                        value: e.target.checked === true ? 1 : 0,
                                    });
                                }}
                            />
                            <span></span>
                            Internet
                        </label>

                        <label className="custom-checkbox" htmlFor="salon">
                            <input
                                type="checkbox"
                                name="custom"
                                value={"Salon"}
                                id="salon"
                                checked={IsChecked(state.salon)}
                                onClick={(e) => {
                                    dispatch({ type: "SET_FIELD", field: "salon", value: e.target.checked === true ? 1 : 0 })
                                }}
                            />
                            <span></span>
                            Salon
                        </label>

                        <label className="custom-checkbox" htmlFor="autre">
                            <input
                                type="checkbox"
                                name="custom"
                                id="autre"
                                checked={state.Autre}
                                onChange={() => {
                                    dispatch({
                                        type: "SET_FIELD",
                                        field: "Autre",
                                        value: !state.Autre,
                                    });
                                }}
                            />
                            <span></span>
                            Autre
                        </label>
                    </div>

                    <div className="TextArea">
                        <textarea
                            id="textarea"
                            disabled={!state.Autre}
                            style={{ cursor: !state.Autre ? "not-allowed" : "text", background: !state.Autre ? "var(--gris-clair)" : "#fff" }}
                            value={state.AutreValue}
                            cols={10}
                            onChange={(e) => {
                                if (state.Autre) dispatch({ type: "SET_FIELD", field: "AutreValue", value: e.target.value, });
                            }}
                        ></textarea>
                    </div>
                </div>

                <div className="TextArea-container">
                    <label htmlFor="autreinformation">Donnez-nous les informations que vous voulez :</label>
                    <textarea id="autreinformation" row={6} cols={10} value={state.autreinformation} onChange={(e) => {
                        dispatch({
                            type: "SET_FIELD",
                            field: "autreinformation",
                            value: e.target.value,
                        });
                    }}></textarea>
                </div>
            </div>
        </div>
    )
}

export default InformationAnnexe
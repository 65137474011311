import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../style/RecentContacts.css';
import "../App.css";
import "../style/private/CalendarInput.css";
import "../style/private/CheckboxList.css";
import "../style/private/ErrorReportPopup.css";
import "../style/private/FilterOptions.css";
import "../style/private/Header.css";
import "../style/private/Historic.css";
import "../style/private/Loader.css";
import "../style/private/Logo.css";
import "../style/private/ScoringComponent.css";
import "../style/private/SMSSection.css";
import "../style/private/TextFormatter.css";
import "../style/private/ToggleSlider.css";

function RecentContacts() {
    const [recentContacts, setRecentContacts] = useState([]);
    const [events, setEvents] = useState([]); // State to store available events
    const [selectedEvent, setSelectedEvent] = useState(''); // State for selected event filter
    const [currentPage, setCurrentPage] = useState(1);
    const studentsPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch recent contacts
        const fetchRecentContacts = async () => {
            try {
                const response = await axios.get('https://csmapi.up.csmrouen.info/get-recent-contacts');
                setRecentContacts(response.data);
            } catch (error) {
                console.error('Error fetching students:', error);
            }
        };

        // Fetch available events
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://csmapi.up.csmrouen.info/get-events');
                console.log(response.data);
                // Filter events where options isn't null
                const filteredEvents = response.data.filter(event => event.options !== "");
                setEvents(filteredEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchRecentContacts();
        fetchEvents();
    }, []);

    // Handle event filter change
    const handleEventChange = (event) => {
        setSelectedEvent(event.target.value);
        setCurrentPage(1); // Reset to the first page when event changes
    };

    // Filter contacts based on the selected event
    const filteredContacts = selectedEvent
        ? recentContacts.filter(contact => contact.origin === selectedEvent)
        : recentContacts;

    // Calculate pagination data
    const indexOfLastStudent = currentPage * studentsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
    const currentContacts = filteredContacts.slice(indexOfFirstStudent, indexOfLastStudent);

    // Handle pagination navigation
    const totalPages = Math.ceil(filteredContacts.length / studentsPerPage);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div>
            <div className="arrow-back-container">
                <svg xmlns="http://www.w3.org/2000/svg" height='32' width='32' viewBox="0 0 448 512" onClick={() => navigate(-1)} className="back-arrow"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
            </div>
            <h2>Contacts récents</h2>

            {/* Event Filter Dropdown */}
            <div className="filter-container">
                <label htmlFor="event-select">Filtrer par évènement:</label>
                <select id="event-select" value={selectedEvent} onChange={handleEventChange}>
                    <option value="">Tous les évènements</option>
                    {events.map(event => (
                        <option key={event.id} value={event.name}>{event.name}</option>
                    ))}
                </select>
            </div>

            <div>
                <p>{filteredContacts.length} contact(s)</p>
            </div>
            {/* Contacts Table */}
            <table className="students-table">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Origine</th>
                        <th>Date d'inscription</th>
                    </tr>
                </thead>
                <tbody>
                    {currentContacts.map((contact, index) => (
                        <tr key={index}>
                            <td>{`${contact.first_name} ${contact.last_name}`}</td>
                            <td>{contact.origin}</td>
                            <td>{new Date(contact.date_register).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>Précédent</button>
                <span> Page {currentPage} sur {totalPages} </span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>Suivant</button>
            </div>
        </div>
    );
}

export default RecentContacts;

import React from 'react'

const Diplome = ({ state, dispatch }) => {
    return (
        <div className='classic-container Card'>
            <h3>Diplômes</h3>
            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="bacvalider">BAC Validé : </label>
                    <select
                        className="Selection"
                        id="bacvalider"
                        value={state.BacValider !== null ? state.BacValider.toString() : null}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_FIELD",
                                field: "BacValider",
                                value: e.target.value,
                            })
                        }
                    >
                        <option value={null} hidden>
                            Choisissez
                        </option>
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                    </select>
                </div>

                <div className="input-container">
                    <label htmlFor="dernierdiplome">Dernier diplôme Validé : </label>
                    <select id="dernierdiplome" value={state.DernierDiplome} onChange={(e) => {
                        dispatch({
                            type: "SET_FIELD",
                            field: "DernierDiplome",
                            value: e.target.value,
                        });
                    }}>
                        <option value={null} hidden>Choisissez</option>
                        <option value="brevet">Brevet</option>
                        <option value="BAC">BAC</option>
                        <option value="BTS">BTS</option>
                        <option value="BUT">BUT</option>
                        <option value="licence">Licence</option>
                        <option value="master">Master</option>
                    </select>
                </div>
            </div>

            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="formationactuelle">
                        Formation en cours :
                    </label>
                    <input
                        type="text"
                        id="formationactuelle"
                        placeholder="Intituler de la formation"
                        value={state.FormationActuelle}
                        onChange={(e) => {
                            dispatch({
                                type: "SET_FIELD",
                                field: "FormationActuelle",
                                value: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="villeetablissement">
                        Ville de l&apos;établissement :{" "}
                    </label>
                    <input
                        type="text"
                        id="villeetablissement"
                        placeholder="Nom de la ville"
                        value={state.VilleEtablissement}
                        onChange={(e) => {
                            dispatch({
                                type: "SET_FIELD",
                                field: "VilleEtablissement",
                                value: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>

            <div className="inputs-wrapper">
                <div className="input-container">
                    <label htmlFor="nometablissement">
                        Nom de l&apos;établissement :{" "}
                    </label>
                    <input
                        type="text"
                        id="nometablissement"
                        placeholder="Établissement"
                        value={state.NomEtablissement}
                        onChange={(e) => {
                            dispatch({
                                type: "SET_FIELD",
                                field: "NomEtablissement",
                                value: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        </div >
    )
}

export default Diplome
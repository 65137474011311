import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Required from '../global/SVG_required';
import SignaturePad from './SignaturePad';
import Explanation from '../global/SVG_explanation';
import FolderHeader from './Folder_Header';
import { useAuth } from '../hooks/useAuth';
import FolderAccess from './FolderAccess';
import Popup from '../prospect/components/Popup';
import "./Folder.css"
import "../App.css"
import "../style/private/CalendarInput.css"
import "../style/private/CheckboxList.css"
import "../style/private/ErrorReportPopup.css"
import "../style/private/FilterOptions.css"
import "../style/private/Header.css"
import "../style/private/Historic.css"
import "../style/private/Loader.css"
import "../style/private/Logo.css"
import "../style/private/ScoringComponent.css"
import "../style/private/SMSSection.css"
import "../style/private/TextFormatter.css"
import "../style/private/ToggleSlider.css"
import "../style/private/Home.css"
import "../style/private/Panel.css"
import "../style/private/Update.css"


const FolderPigier = () => {
    const { folder_id } = useParams();
    const [folder, setFolder] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const { connected_id } = useAuth();
    const handleLoginSuccess = async () => {
        const id = folder_id;
        if (connected_id == null || connected_id === undefined || connected_id === '') {
            try {
                await axios.put(`/update-folder-last-conn/${id}`);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la dernière connexion :', error);
            }
        }
        setIsConnected(true);
    };
    const handleFetch = async () => {
        try {
            const response = await axios.get(`/get-folder-id/${folder_id}`);
            setFolder(response.data.values[0]);
        } catch (err) {
            setFolder(null);
        }
    };
    useEffect(() => {
        handleFetch();
    }, [])
    return (
        <>
            {isConnected ? <></> : <FolderAccess onLoginSuccess={handleLoginSuccess} />}
            {isConnected && folder != null ? (
                <div>
                    <FolderHeader student_name={folder.folder_student_name} folder_id={folder.folder_id}></FolderHeader>
                    <h1 className='title-historic'>Dossier de candidature :</h1>
                    <h1 className='title-historic'>Documents à fournir : 0/6</h1>
                    <div className='folder_files_container'>
                        <Popup className='validate' trigger={"Ajouter un fichier à déposer"}>
                            <div onClick={(event) => event.stopPropagation()}>
                                <h1>Déposer un fichier.</h1>
                                <select className='formInput'>
                                    <option>Carte d'identité</option>
                                </select>
                                <input type='file' className='formInput'></input>
                            </div>
                            <button className='formInput'>Envoyer</button>
                        </Popup>
                        <div>
                            <h5><Required />Carte nationale d'identité :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5><Required />Photo d'identité :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5><Required />CV :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5><Required />Lettre de motivation :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5><Required />Bulletins scolaires (2nde à Terminale) :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5><Required />Bulletins scolaires (Post BAC) : </h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5>Relevé de notes <Explanation explanation="Relevé de notes du BAC ou relevé de notes des épreuves anticipées de 1ere (Pour les candidats en 1ere année qui n'ont pas encore le bac)" /> :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5>Diplômes obtenus après le BAC <Explanation explanation="Pour les diplômes étrangers, merci de fournir une attestation de comparabilité via le centre ENIC-NARIC (vous pouvez en faire la demande via ce lien : https://phoenix.france-education-international.fr/inscriptions/)" /> :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5>Certification langue française :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5>Productions créatives :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                        <div>
                            <h5>Attestation RQTH :</h5>
                            <div className='folder'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>
                            </div>
                        </div>
                    </div>
                    <h1 className='title-historic'>Signature : </h1>
                    <div className='folder_info_info'>Je soussigné(e), Prénom Nom déclare avoir pris connaissance des conditions et règlements des épreuves d'admission de l'ISCOM et demande à participer à l'une des sessions.
                        <p>Fait à <input type='text' className="folder_input"></input>le<input type='date' className="folder_input"></input></p>
                        <SignaturePad></SignaturePad>
                    </div>
                    <button className='login-button'>Finaliser la candidature</button>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default FolderPigier;
